.slidingBackground {
  //   background: url('../assets/testimonialsSliderImage.svg');
  background: url('./testimonialsSliderImage.webp');
  background-repeat: repeat-x;
  height: 422px;

  width: 3080px;
  animation: slide 50s linear infinite;
  position: relative;
  z-index: 5;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1540px, 0, 0);
  }
}
